<template>
  <div class="container">
    <!-- 左侧菜单 -->
    <Menu
      theme="light"
      :active-name="selectMenuName"
      @on-select="selectMenu"
      class="menu"
    >
      <MenuGroup title="菜单栏">
        <MenuItem name="ChangeInfo">基本信息</MenuItem>
        <MenuItem name="ChangeFamily">家庭成员</MenuItem>
        <MenuItem name="ChangeNeed">需求评估</MenuItem>
      </MenuGroup>
    </Menu>
    <div class="rightContent">
      <keep-alive>
        <component
          :is="componentName"
          :formData="formData"
          :orgCodeList="orgCodeList"
          :userId="userId"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ChangeFamily from "@/views/archives/childrens/changeArchivesChildrens/changeFamily";
import ChangeInfo from "@/views/archives/childrens/changeArchivesChildrens/changeInfo";
import ChangeNeed from "@/views/archives/childrens/changeArchivesChildrens/changeNeed";
export default {
  props: ["info","userId"],
  data() {
    return {
      selectMenuName: "ChangeInfo",
      componentName: "ChangeInfo",
      formData: {
        archiveDetailVo:{},
        userNeedyVo:{},
        userContactsVo:{},
        userDetailVo:{},
        userHouseVo:{},
        userHealthVo:{},
        userIncomeVo:{},
        archiveQuestionConfigVos:{},
        archiveServiceVos:{},
        archiveAssessNeedVo:{},
        userMemberVos:[],
        addUserIncomeRo: {}
      },
      orgCodeList: [],
      orgName: "",
    };
  },
  async created() {
      //获取所属社区
    await this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList && res.dataList.length > 0) {
            this.orgCodeList = res.dataList.map(item => {
              return {
                label: item.fullName,
                value: item.code
              }
            });
          } else {
            this.$Message["error"]({
              background: true,
              content: "获取社区列表失败！请联系管理员",
            });
          }
        })
   await this.$post('/archives/api/pc/archiveUser/updateArchiveDetail',{
      archiveId: this.info
    }).then(res => {
      if(res.code == 200 && res.data) {
        for(let k in res.data) {
          if(res.data[k] == null && k != 'helpServiceContent' && k != 'existingServiceContent') {
            this.formData[k] = {}
          }else {
            this.formData[k] = res.data[k];
          }
        }
        // 建档时间
        if(this.formData.archiveDetailVo.archiveCreate) {
          this.formData.archiveDetailVo.archiveCreate = new Date(this.formData.archiveDetailVo.archiveCreate)
        }
        // 去掉所属社区的orgName
        if(this.formData.archiveDetailVo.orgCode) {
          this.formData.archiveDetailVo.orgName = ''
        }
        this.$Message.destroy();
      }else {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: res.desc
        })
      }
    })
  },
 methods: {
      //选择菜单
  selectMenu(name) {
    this.componentName = name;
  },
  saveFn() {
    if(this.formData.archiveDetailVo.orgCode) {
        this.orgCodeList.map(item => {
          if(item.value == this.formData.archiveDetailVo.orgCode) {
            this.orgName = item.label;
          }
        })
      }
     // 校验所属社区
    if(!((this.formData.archiveDetailVo.orgCode && this.orgName) || this.formData.archiveDetailVo.orgName)) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "所属社区不能为空"
        })
        return;
    }
     // 校验人员类别
    if(this.formData.archiveDetailVo.difficultType == '' || this.formData.archiveDetailVo.difficultType == undefined) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "人员类别不能为空"
        })
        return;
    }
    // 校验姓名
    if(this.formData.userNeedyVo.name == '' || this.formData.userNeedyVo.name == undefined) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "姓名不能为空"
        })
        return;
    }
    // 校验建档时间
    if(!this.formData.archiveDetailVo.archiveCreate) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "建档时间不能为空"
        })
        return;
    }
    // 校验性别
    if(this.formData.userNeedyVo.sex == '' || this.formData.userNeedyVo.sex == undefined) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "性别不能为空"
        })
        return;
    }
    
    // 校验身份证号
    if(this.formData.archiveDetailVo.idNum == '' || this.formData.archiveDetailVo.idNum == undefined) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "身份证号码不能为空"
        })
        return;
    }
    if(!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.formData.archiveDetailVo.idNum)) {
      this.$Message.error({
          background: true,
          duration: 3,
          content: "请输入正确的身份证号码"
        })
        return;
    }
      this.$Message.loading({
        content: "正在保存...",
        duration: 0
      })
      this.$post('/archives/api/pc/archiveUser/updateArchive',{
        addArchiveUserRo: {
          ...this.formData.archiveDetailVo,
          orgCode: this.formData.archiveDetailVo.orgCode,
          orgName: this.formData.archiveDetailVo.orgCode ? this.orgName : this.formData.archiveDetailVo.orgName,
          archiveCreate: this.$core.formatDate(this.formData.archiveDetailVo.archiveCreate,"yyyy-MM-dd"),
          oemCode: parent.vue.oemInfo.oemCode
        },
        addUserNeedyRo: {
          ...this.formData.userNeedyVo,
          idNum: this.formData.archiveDetailVo.idNum
        },
        addUserContactsRo: this.formData.userContactsVo,
        addUserDetailRo: this.formData.userDetailVo,
        addUserMemberRo: this.formData.userMemberVos,
        addUserHouseRo: this.formData.userHouseVo,
        addArchiveAssessNeedRo: this.formData.archiveAssessNeedVo,
        addUserHealthRo: this.formData.userHealthVo,
        addUserIncomeRo: this.formData.addUserIncomeRo,
        addArchiveAssessRo: this.formData.archiveAssessNeedVo,
        existingServiceContent: this.formData.existingServiceContent,
        helpServiceContent: this.formData.helpServiceContent,
        archiveId: this.info,
        userId: this.userId,
        mobile: this.formData.archiveDetailVo.mobile,
        staffId: parent.vue.loginInfo.userinfo.custGlobalId,
        staffName: parent.vue.loginInfo.userinfo.realName
      },{"Content-Type":"application/json"}).then(res => {
        this.$Message.destroy();
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
          this.$emit('saveSuccess')
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "保存失败"
        })
      })
    }
 },
  components: {
    ChangeFamily,
    ChangeInfo,
    ChangeNeed,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-menu-item-group-title {
  padding-left: 0 !important;
}
.menu {
  position: fixed;
  left: 30px;
  top: 52px;
  width: 210px !important;
  height: calc(100vh - 105px);
  z-index: 1;
}
.rightContent {
  margin-left: 240px;
  padding: 10px;
}
</style>